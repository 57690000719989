@keyframes ldt-power-on {
    0%, 20%, 100% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(0.05, 0.05);
      transform: scale(0.05, 0.05);
    }
    20% {
      -webkit-transform: scale(1, 0.1);
      transform: scale(1, 0.1);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  @-webkit-keyframes ldt-power-on {
    0%, 20%, 100% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(0.05, 0.05);
      transform: scale(0.05, 0.05);
    }
    20% {
      -webkit-transform: scale(1, 0.1);
      transform: scale(1, 0.1);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  .ldt-power-on {
    -webkit-transform: scale(0.05, 0.05);
    transform: scale(0.05, 0.05);
    -webkit-animation: ldt-power-on 1s forwards;
    animation: ldt-power-on 1s forwards;
  }
  @keyframes ldt-power-off {
    0%, 20%, 100% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    20% {
      -webkit-transform: scale(1, 0.1);
      transform: scale(1, 0.1);
    }
    100% {
      -webkit-transform: scale(0.05, 0.05);
      transform: scale(0.05, 0.05);
    }
  }
  @-webkit-keyframes ldt-power-off {
    0%, 20%, 100% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
    20% {
      -webkit-transform: scale(1, 0.1);
      transform: scale(1, 0.1);
    }
    100% {
      -webkit-transform: scale(0.05, 0.05);
      transform: scale(0.05, 0.05);
    }
  }
  .ldt-power-off {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-animation: ldt-power-off 1s forwards;
    animation: ldt-power-off 1s forwards;
  }
  @keyframes ldt-bounce-in {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    5% {
      -webkit-transform: scale(0.0846);
      transform: scale(0.0846);
    }
    6% {
      -webkit-transform: scale(0.191);
      transform: scale(0.191);
    }
    7% {
      -webkit-transform: scale(0.3255);
      transform: scale(0.3255);
    }
    8% {
      -webkit-transform: scale(0.4804);
      transform: scale(0.4804);
    }
    9% {
      -webkit-transform: scale(0.646);
      transform: scale(0.646);
    }
    10% {
      -webkit-transform: scale(0.8116);
      transform: scale(0.8116);
    }
    11% {
      -webkit-transform: scale(0.9665);
      transform: scale(0.9665);
    }
    12% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    13% {
      -webkit-transform: scale(1.2069);
      transform: scale(1.2069);
    }
    14% {
      -webkit-transform: scale(1.2796);
      transform: scale(1.2796);
    }
    19% {
      -webkit-transform: scale(1.1558);
      transform: scale(1.1558);
    }
    20% {
      -webkit-transform: scale(1.07);
      transform: scale(1.07);
    }
    21% {
      -webkit-transform: scale(0.9823);
      transform: scale(0.9823);
    }
    22% {
      -webkit-transform: scale(0.901);
      transform: scale(0.901);
    }
    23% {
      -webkit-transform: scale(0.8328);
      transform: scale(0.8328);
    }
    25% {
      -webkit-transform: scale(0.7551);
      transform: scale(0.7551);
    }
    29% {
      -webkit-transform: scale(0.8516);
      transform: scale(0.8516);
    }
    31% {
      -webkit-transform: scale(0.973);
      transform: scale(0.973);
    }
    33% {
      -webkit-transform: scale(1.0857);
      transform: scale(1.0857);
    }
    35% {
      -webkit-transform: scale(1.1538);
      transform: scale(1.1538);
    }
    40% {
      -webkit-transform: scale(1.0771);
      transform: scale(1.0771);
    }
    42% {
      -webkit-transform: scale(0.9954);
      transform: scale(0.9954);
    }
    45% {
      -webkit-transform: scale(0.9057);
      transform: scale(0.9057);
    }
    52% {
      -webkit-transform: scale(0.9908);
      transform: scale(0.9908);
    }
    56% {
      -webkit-transform: scale(1.0623);
      transform: scale(1.0623);
    }
    64% {
      -webkit-transform: scale(0.9845);
      transform: scale(0.9845);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @-webkit-keyframes ldt-bounce-in {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    5% {
      -webkit-transform: scale(0.0846);
      transform: scale(0.0846);
    }
    6% {
      -webkit-transform: scale(0.191);
      transform: scale(0.191);
    }
    7% {
      -webkit-transform: scale(0.3255);
      transform: scale(0.3255);
    }
    8% {
      -webkit-transform: scale(0.4804);
      transform: scale(0.4804);
    }
    9% {
      -webkit-transform: scale(0.646);
      transform: scale(0.646);
    }
    10% {
      -webkit-transform: scale(0.8116);
      transform: scale(0.8116);
    }
    11% {
      -webkit-transform: scale(0.9665);
      transform: scale(0.9665);
    }
    12% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    13% {
      -webkit-transform: scale(1.2069);
      transform: scale(1.2069);
    }
    14% {
      -webkit-transform: scale(1.2796);
      transform: scale(1.2796);
    }
    19% {
      -webkit-transform: scale(1.1558);
      transform: scale(1.1558);
    }
    20% {
      -webkit-transform: scale(1.07);
      transform: scale(1.07);
    }
    21% {
      -webkit-transform: scale(0.9823);
      transform: scale(0.9823);
    }
    22% {
      -webkit-transform: scale(0.901);
      transform: scale(0.901);
    }
    23% {
      -webkit-transform: scale(0.8328);
      transform: scale(0.8328);
    }
    25% {
      -webkit-transform: scale(0.7551);
      transform: scale(0.7551);
    }
    29% {
      -webkit-transform: scale(0.8516);
      transform: scale(0.8516);
    }
    31% {
      -webkit-transform: scale(0.973);
      transform: scale(0.973);
    }
    33% {
      -webkit-transform: scale(1.0857);
      transform: scale(1.0857);
    }
    35% {
      -webkit-transform: scale(1.1538);
      transform: scale(1.1538);
    }
    40% {
      -webkit-transform: scale(1.0771);
      transform: scale(1.0771);
    }
    42% {
      -webkit-transform: scale(0.9954);
      transform: scale(0.9954);
    }
    45% {
      -webkit-transform: scale(0.9057);
      transform: scale(0.9057);
    }
    52% {
      -webkit-transform: scale(0.9908);
      transform: scale(0.9908);
    }
    56% {
      -webkit-transform: scale(1.0623);
      transform: scale(1.0623);
    }
    64% {
      -webkit-transform: scale(0.9845);
      transform: scale(0.9845);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .ldt-bounce-in {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation: ldt-bounce-in 1s ease-out forwards;
    animation: ldt-bounce-in 1s ease-out forwards;
  }
  @keyframes ldt-bounce-out {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    5% {
      -webkit-transform: scale(0.8509);
      transform: scale(0.8509);
    }
    6% {
      -webkit-transform: scale(0.6903);
      transform: scale(0.6903);
    }
    7% {
      -webkit-transform: scale(0.5201);
      transform: scale(0.5201);
    }
    8% {
      -webkit-transform: scale(0.3568);
      transform: scale(0.3568);
    }
    9% {
      -webkit-transform: scale(0.2158);
      transform: scale(0.2158);
    }
    10% {
      -webkit-transform: scale(0.1095);
      transform: scale(0.1095);
    }
    11% {
      -webkit-transform: scale(0.0452);
      transform: scale(0.0452);
    }
    15% {
      -webkit-transform: scale(0.1568);
      transform: scale(0.1568);
    }
    16% {
      -webkit-transform: scale(0.2268);
      transform: scale(0.2268);
    }
    17% {
      -webkit-transform: scale(0.288);
      transform: scale(0.288);
    }
    19% {
      -webkit-transform: scale(0.3481);
      transform: scale(0.3481);
    }
    22% {
      -webkit-transform: scale(0.2549);
      transform: scale(0.2549);
    }
    23% {
      -webkit-transform: scale(0.1947);
      transform: scale(0.1947);
    }
    24% {
      -webkit-transform: scale(0.1346);
      transform: scale(0.1346);
    }
    25% {
      -webkit-transform: scale(0.0831);
      transform: scale(0.0831);
    }
    27% {
      -webkit-transform: scale(0.0299);
      transform: scale(0.0299);
    }
    30% {
      -webkit-transform: scale(0.0845);
      transform: scale(0.0845);
    }
    32% {
      -webkit-transform: scale(0.1599);
      transform: scale(0.1599);
    }
    35% {
      -webkit-transform: scale(0.2151);
      transform: scale(0.2151);
    }
    38% {
      -webkit-transform: scale(0.1603);
      transform: scale(0.1603);
    }
    40% {
      -webkit-transform: scale(0.101);
      transform: scale(0.101);
    }
    73% {
      -webkit-transform: scale(0.0506);
      transform: scale(0.0506);
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  @-webkit-keyframes ldt-bounce-out {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    5% {
      -webkit-transform: scale(0.8509);
      transform: scale(0.8509);
    }
    6% {
      -webkit-transform: scale(0.6903);
      transform: scale(0.6903);
    }
    7% {
      -webkit-transform: scale(0.5201);
      transform: scale(0.5201);
    }
    8% {
      -webkit-transform: scale(0.3568);
      transform: scale(0.3568);
    }
    9% {
      -webkit-transform: scale(0.2158);
      transform: scale(0.2158);
    }
    10% {
      -webkit-transform: scale(0.1095);
      transform: scale(0.1095);
    }
    11% {
      -webkit-transform: scale(0.0452);
      transform: scale(0.0452);
    }
    15% {
      -webkit-transform: scale(0.1568);
      transform: scale(0.1568);
    }
    16% {
      -webkit-transform: scale(0.2268);
      transform: scale(0.2268);
    }
    17% {
      -webkit-transform: scale(0.288);
      transform: scale(0.288);
    }
    19% {
      -webkit-transform: scale(0.3481);
      transform: scale(0.3481);
    }
    22% {
      -webkit-transform: scale(0.2549);
      transform: scale(0.2549);
    }
    23% {
      -webkit-transform: scale(0.1947);
      transform: scale(0.1947);
    }
    24% {
      -webkit-transform: scale(0.1346);
      transform: scale(0.1346);
    }
    25% {
      -webkit-transform: scale(0.0831);
      transform: scale(0.0831);
    }
    27% {
      -webkit-transform: scale(0.0299);
      transform: scale(0.0299);
    }
    30% {
      -webkit-transform: scale(0.0845);
      transform: scale(0.0845);
    }
    32% {
      -webkit-transform: scale(0.1599);
      transform: scale(0.1599);
    }
    35% {
      -webkit-transform: scale(0.2151);
      transform: scale(0.2151);
    }
    38% {
      -webkit-transform: scale(0.1603);
      transform: scale(0.1603);
    }
    40% {
      -webkit-transform: scale(0.101);
      transform: scale(0.101);
    }
    73% {
      -webkit-transform: scale(0.0506);
      transform: scale(0.0506);
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  .ldt-bounce-out {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation: ldt-bounce-out 1s ease-out forwards;
    animation: ldt-bounce-out 1s ease-out forwards;
  }
  @keyframes ldt-jump-in {
    0%, 45%, 72% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    23%, 59%, 80% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    23% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    45% {
      -webkit-transform: scale(0.64);
      transform: scale(0.64);
    }
    59% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    72% {
      -webkit-transform: scale(0.88);
      transform: scale(0.88);
    }
    80% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @-webkit-keyframes ldt-jump-in {
    0%, 45%, 72% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    23%, 59%, 80% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    23% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    45% {
      -webkit-transform: scale(0.64);
      transform: scale(0.64);
    }
    59% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    72% {
      -webkit-transform: scale(0.88);
      transform: scale(0.88);
    }
    80% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .ldt-jump-in {
    -webkit-animation: ldt-jump-in 1s forwards;
    animation: ldt-jump-in 1s forwards;
  }
  @keyframes ldt-jump-out {
    0%, 45%, 72% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    23%, 59%, 80% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    23% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    45% {
      -webkit-transform: scale(0.36);
      transform: scale(0.36);
    }
    59% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    72% {
      -webkit-transform: scale(0.12);
      transform: scale(0.12);
    }
    80% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  @-webkit-keyframes ldt-jump-out {
    0%, 45%, 72% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    23%, 59%, 80% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    23% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    45% {
      -webkit-transform: scale(0.36);
      transform: scale(0.36);
    }
    59% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    72% {
      -webkit-transform: scale(0.12);
      transform: scale(0.12);
    }
    80% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  .ldt-jump-out {
    -webkit-animation: ldt-jump-out 1s forwards;
    animation: ldt-jump-out 1s forwards;
  }
  @keyframes ldt-jump-alt-in {
    0%, 23%, 41%, 54%, 65%, 73%, 80%, 85%, 89% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    11%, 32%, 48%, 60%, 69%, 77%, 82%, 87%, 90% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    11% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    23% {
      -webkit-transform: scale(0.4);
      transform: scale(0.4);
    }
    32% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    41% {
      -webkit-transform: scale(0.64);
      transform: scale(0.64);
    }
    48% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    54% {
      -webkit-transform: scale(0.78);
      transform: scale(0.78);
    }
    60% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    65% {
      -webkit-transform: scale(0.88);
      transform: scale(0.88);
    }
    69% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    73% {
      -webkit-transform: scale(0.92);
      transform: scale(0.92);
    }
    77% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    80% {
      -webkit-transform: scale(0.96);
      transform: scale(0.96);
    }
    82% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    85% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
    }
    87% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    89% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
    }
    90% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @-webkit-keyframes ldt-jump-alt-in {
    0%, 23%, 41%, 54%, 65%, 73%, 80%, 85%, 89% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    11%, 32%, 48%, 60%, 69%, 77%, 82%, 87%, 90% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    11% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    23% {
      -webkit-transform: scale(0.4);
      transform: scale(0.4);
    }
    32% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    41% {
      -webkit-transform: scale(0.64);
      transform: scale(0.64);
    }
    48% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    54% {
      -webkit-transform: scale(0.78);
      transform: scale(0.78);
    }
    60% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    65% {
      -webkit-transform: scale(0.88);
      transform: scale(0.88);
    }
    69% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    73% {
      -webkit-transform: scale(0.92);
      transform: scale(0.92);
    }
    77% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    80% {
      -webkit-transform: scale(0.96);
      transform: scale(0.96);
    }
    82% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    85% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
    }
    87% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    89% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
    }
    90% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .ldt-jump-alt-in {
    -webkit-animation: ldt-jump-alt-in 1s forwards;
    animation: ldt-jump-alt-in 1s forwards;
  }
  @keyframes ldt-jump-alt-out {
    0%, 23%, 41%, 54%, 65%, 73%, 80%, 85%, 89% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    11%, 32%, 48%, 60%, 69%, 77%, 82%, 87%, 90% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    11% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    23% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
    }
    32% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    41% {
      -webkit-transform: scale(0.36);
      transform: scale(0.36);
    }
    48% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    54% {
      -webkit-transform: scale(0.22);
      transform: scale(0.22);
    }
    60% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    65% {
      -webkit-transform: scale(0.12);
      transform: scale(0.12);
    }
    69% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    73% {
      -webkit-transform: scale(0.08);
      transform: scale(0.08);
    }
    77% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    80% {
      -webkit-transform: scale(0.04);
      transform: scale(0.04);
    }
    82% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    85% {
      -webkit-transform: scale(0.02);
      transform: scale(0.02);
    }
    87% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    89% {
      -webkit-transform: scale(0.02);
      transform: scale(0.02);
    }
    90% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  @-webkit-keyframes ldt-jump-alt-out {
    0%, 23%, 41%, 54%, 65%, 73%, 80%, 85%, 89% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    11%, 32%, 48%, 60%, 69%, 77%, 82%, 87%, 90% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    11% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    23% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
    }
    32% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    41% {
      -webkit-transform: scale(0.36);
      transform: scale(0.36);
    }
    48% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    54% {
      -webkit-transform: scale(0.22);
      transform: scale(0.22);
    }
    60% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    65% {
      -webkit-transform: scale(0.12);
      transform: scale(0.12);
    }
    69% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    73% {
      -webkit-transform: scale(0.08);
      transform: scale(0.08);
    }
    77% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    80% {
      -webkit-transform: scale(0.04);
      transform: scale(0.04);
    }
    82% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    85% {
      -webkit-transform: scale(0.02);
      transform: scale(0.02);
    }
    87% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    89% {
      -webkit-transform: scale(0.02);
      transform: scale(0.02);
    }
    90% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  .ldt-jump-alt-out {
    -webkit-animation: ldt-jump-alt-out 1s forwards;
    animation: ldt-jump-alt-out 1s forwards;
  }
  @keyframes ldt-zoom-in {
    0%, 20%, 100% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @-webkit-keyframes ldt-zoom-in {
    0%, 20%, 100% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .ldt-zoom-in {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation: ldt-zoom-in 1s forwards;
    animation: ldt-zoom-in 1s forwards;
  }
  @keyframes ldt-zoom-out {
    0%, 20%, 100% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  @-webkit-keyframes ldt-zoom-out {
    0%, 20%, 100% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  .ldt-zoom-out {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation: ldt-zoom-out 1s forwards;
    animation: ldt-zoom-out 1s forwards;
  }
  @keyframes ldt-grow-h {
    0% {
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  @-webkit-keyframes ldt-grow-h {
    0% {
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  @keyframes ldt-grow-v {
    0% {
      -webkit-transform: scale(1, 0);
      transform: scale(1, 0);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  @-webkit-keyframes ldt-grow-v {
    0% {
      -webkit-transform: scale(1, 0);
      transform: scale(1, 0);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  .ldt-grow-ltr {
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-animation: ldt-grow-h 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-grow-h 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    -webkit-transform-origin: 0 !important;
    transform-origin: 0 !important;
  }
  .ldt-grow-rtl {
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-animation: ldt-grow-h 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-grow-h 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    -webkit-transform-origin: 100% !important;
    transform-origin: 100% !important;
  }
  .ldt-grow-ttb {
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-animation: ldt-grow-v 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-grow-v 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    -webkit-transform-origin: 50% 0 !important;
    transform-origin: 50% 0 !important;
  }
  .ldt-grow-btt {
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-animation: ldt-grow-v 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-grow-v 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    -webkit-transform-origin: 50% 100% !important;
    transform-origin: 50% 100% !important;
  }
  @keyframes ldt-flip-h {
    0% {
      -webkit-transform: rotateX(90deg);
      transform: rotateX(90deg);
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
  }
  @-webkit-keyframes ldt-flip-h {
    0% {
      -webkit-transform: rotateX(90deg);
      transform: rotateX(90deg);
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
  }
  .ldt-flip-h {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    perspective: 500px;
    -webkit-animation: ldt-flip-h 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-flip-h 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-flip-v {
    0% {
      -webkit-transform: rotateY(90deg);
      transform: rotateY(90deg);
    }
    100% {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes ldt-flip-v {
    0% {
      -webkit-transform: rotateY(90deg);
      transform: rotateY(90deg);
    }
    100% {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg);
    }
  }
  .ldt-flip-v {
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    perspective: 500px;
    -webkit-animation: ldt-flip-v 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-flip-v 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-float-up-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(0, 30%);
      transform: translate(0, 30%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
  }
  @-webkit-keyframes ldt-float-up-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(0, 30%);
      transform: translate(0, 30%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
  }
  .ldt-float-up-in {
    -webkit-transform: translate(0, 30%);
    transform: translate(0, 30%);
    opacity: 0;
    -webkit-animation: ldt-float-up-in 1s forwards;
    animation: ldt-float-up-in 1s forwards;
  }
  @keyframes ldt-float-down-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(0, -30%);
      transform: translate(0, -30%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
  }
  @-webkit-keyframes ldt-float-down-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(0, -30%);
      transform: translate(0, -30%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
  }
  .ldt-float-down-in {
    -webkit-transform: translate(0, -30%);
    transform: translate(0, -30%);
    opacity: 0;
    -webkit-animation: ldt-float-down-in 1s forwards;
    animation: ldt-float-down-in 1s forwards;
  }
  @keyframes ldt-float-left-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(30%, 0);
      transform: translate(30%, 0);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0%, 0);
      transform: translate(0%, 0);
    }
  }
  @-webkit-keyframes ldt-float-left-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(30%, 0);
      transform: translate(30%, 0);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0%, 0);
      transform: translate(0%, 0);
    }
  }
  .ldt-float-left-in {
    -webkit-transform: translate(30%, 0);
    transform: translate(30%, 0);
    opacity: 0;
    -webkit-animation: ldt-float-left-in 1s forwards;
    animation: ldt-float-left-in 1s forwards;
  }
  @keyframes ldt-float-right-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(-30%, 0);
      transform: translate(-30%, 0);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0%, 0);
      transform: translate(0%, 0);
    }
  }
  @-webkit-keyframes ldt-float-right-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(-30%, 0);
      transform: translate(-30%, 0);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0%, 0);
      transform: translate(0%, 0);
    }
  }
  .ldt-float-right-in {
    -webkit-transform: translate(-30%, 0);
    transform: translate(-30%, 0);
    opacity: 0;
    -webkit-animation: ldt-float-right-in 1s forwards;
    animation: ldt-float-right-in 1s forwards;
  }
  @keyframes ldt-slide-left-in {
    0% {
      -webkit-transform: translate(-200%, 0);
      transform: translate(-200%, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-slide-left-in {
    0% {
      -webkit-transform: translate(-200%, 0);
      transform: translate(-200%, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .ldt-slide-left-in {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0);
    opacity: 0;
    -webkit-animation: ldt-slide-left-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-left-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-right-in {
    0% {
      -webkit-transform: translate(200%, 0);
      transform: translate(200%, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-slide-right-in {
    0% {
      -webkit-transform: translate(200%, 0);
      transform: translate(200%, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .ldt-slide-right-in {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0);
    opacity: 0;
    -webkit-animation: ldt-slide-right-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-right-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-top-in {
    0% {
      -webkit-transform: translate(0, -200%);
      transform: translate(0, -200%);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-slide-top-in {
    0% {
      -webkit-transform: translate(0, -200%);
      transform: translate(0, -200%);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .ldt-slide-top-in {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%);
    opacity: 0;
    -webkit-animation: ldt-slide-top-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-top-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-bottom-in {
    0% {
      -webkit-transform: translate(0, 200%);
      transform: translate(0, 200%);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-slide-bottom-in {
    0% {
      -webkit-transform: translate(0, 200%);
      transform: translate(0, 200%);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .ldt-slide-bottom-in {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%);
    opacity: 0;
    -webkit-animation: ldt-slide-bottom-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-bottom-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-left-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(-200%, 0);
      transform: translate(-200%, 0);
      opacity: 0;
    }
  }
  @-webkit-keyframes ldt-slide-left-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(-200%, 0);
      transform: translate(-200%, 0);
      opacity: 0;
    }
  }
  .ldt-slide-left-out {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation: ldt-slide-left-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-left-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-right-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(200%, 0);
      transform: translate(200%, 0);
      opacity: 0;
    }
  }
  @-webkit-keyframes ldt-slide-right-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(200%, 0);
      transform: translate(200%, 0);
      opacity: 0;
    }
  }
  .ldt-slide-right-out {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation: ldt-slide-right-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-right-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-top-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(0, -200%);
      transform: translate(0, -200%);
      opacity: 0;
    }
  }
  @-webkit-keyframes ldt-slide-top-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(0, -200%);
      transform: translate(0, -200%);
      opacity: 0;
    }
  }
  .ldt-slide-top-out {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation: ldt-slide-top-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-top-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-bottom-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(0, 200%);
      transform: translate(0, 200%);
      opacity: 0;
    }
  }
  @-webkit-keyframes ldt-slide-bottom-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(0, 200%);
      transform: translate(0, 200%);
      opacity: 0;
    }
  }
  .ldt-slide-bottom-out {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation: ldt-slide-bottom-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-bottom-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-blur-in {
    0% {
      filter: blur(5px);
      opacity: 0;
    }
    90% {
      filter: blur(0px);
      opacity: 1;
    }
    100% {
      filter: blur(0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-blur-in {
    0% {
      filter: blur(5px);
      opacity: 0;
    }
    90% {
      filter: blur(0px);
      opacity: 1;
    }
    100% {
      filter: blur(0px);
      opacity: 1;
    }
  }
  .ldt-blur-in {
    filter: blur(5px);
    opacity: 0;
    -webkit-animation: ldt-blur-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-blur-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-blur-out {
    0% {
      filter: blur(0);
      opacity: 1;
    }
    10% {
      filter: blur(0);
      opacity: 1;
    }
    100% {
      filter: blur(5px);
      opacity: 0;
    }
  }
  @-webkit-keyframes ldt-blur-out {
    0% {
      filter: blur(0);
      opacity: 1;
    }
    10% {
      filter: blur(0);
      opacity: 1;
    }
    100% {
      filter: blur(5px);
      opacity: 0;
    }
  }
  .ldt-blur-out {
    filter: blur(0);
    opacity: 1;
    -webkit-animation: ldt-blur-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-blur-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-rush-left-in {
    0% {
      -webkit-transform: translate(-200%, 0) skewX(45deg);
      transform: translate(-200%, 0) skewX(45deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    38% {
      -webkit-transform: translate(40%, 0) skewX(-35deg);
      transform: translate(40%, 0) skewX(-35deg);
    }
    56% {
      -webkit-transform: translate(-20%, 0) skewX(12deg);
      transform: translate(-20%, 0) skewX(12deg);
    }
    75% {
      -webkit-transform: translate(10%, 0) skewX(-7deg);
      transform: translate(10%, 0) skewX(-7deg);
    }
    100% {
      -webkit-transform: translate(0%, 0) skewX(0deg);
      transform: translate(0%, 0) skewX(0deg);
    }
  }
  @-webkit-keyframes ldt-rush-left-in {
    0% {
      -webkit-transform: translate(-200%, 0) skewX(45deg);
      transform: translate(-200%, 0) skewX(45deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    38% {
      -webkit-transform: translate(40%, 0) skewX(-35deg);
      transform: translate(40%, 0) skewX(-35deg);
    }
    56% {
      -webkit-transform: translate(-20%, 0) skewX(12deg);
      transform: translate(-20%, 0) skewX(12deg);
    }
    75% {
      -webkit-transform: translate(10%, 0) skewX(-7deg);
      transform: translate(10%, 0) skewX(-7deg);
    }
    100% {
      -webkit-transform: translate(0%, 0) skewX(0deg);
      transform: translate(0%, 0) skewX(0deg);
    }
  }
  .ldt-rush-left-in {
    -webkit-transform: translate(-200%, 0) skewX(45deg);
    transform: translate(-200%, 0) skewX(45deg);
    -webkit-animation: ldt-rush-left-in 1s linear forwards;
    animation: ldt-rush-left-in 1s linear forwards;
  }
  @keyframes ldt-rush-right-in {
    0% {
      -webkit-transform: translate(200%, 0) skewX(-45deg);
      transform: translate(200%, 0) skewX(-45deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    38% {
      -webkit-transform: translate(-40%, 0) skewX(35deg);
      transform: translate(-40%, 0) skewX(35deg);
    }
    56% {
      -webkit-transform: translate(20%, 0) skewX(-12deg);
      transform: translate(20%, 0) skewX(-12deg);
    }
    75% {
      -webkit-transform: translate(-10%, 0) skewX(7deg);
      transform: translate(-10%, 0) skewX(7deg);
    }
    100% {
      -webkit-transform: translate(0%, 0) skewX(0deg);
      transform: translate(0%, 0) skewX(0deg);
    }
  }
  @-webkit-keyframes ldt-rush-right-in {
    0% {
      -webkit-transform: translate(200%, 0) skewX(-45deg);
      transform: translate(200%, 0) skewX(-45deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    38% {
      -webkit-transform: translate(-40%, 0) skewX(35deg);
      transform: translate(-40%, 0) skewX(35deg);
    }
    56% {
      -webkit-transform: translate(20%, 0) skewX(-12deg);
      transform: translate(20%, 0) skewX(-12deg);
    }
    75% {
      -webkit-transform: translate(-10%, 0) skewX(7deg);
      transform: translate(-10%, 0) skewX(7deg);
    }
    100% {
      -webkit-transform: translate(0%, 0) skewX(0deg);
      transform: translate(0%, 0) skewX(0deg);
    }
  }
  .ldt-rush-right-in {
    -webkit-transform: translate(200%, 0) skewX(-45deg);
    transform: translate(200%, 0) skewX(-45deg);
    -webkit-animation: ldt-rush-right-in 1s linear forwards;
    animation: ldt-rush-right-in 1s linear forwards;
  }
  @keyframes ldt-fall-in {
    0%, 35%, 59%, 75% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    18%, 47%, 67%, 80% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: translate(0, -100%);
      transform: translate(0, -100%);
    }
    18% {
      opacity: 1;
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
    35% {
      -webkit-transform: translate(0, -46%);
      transform: translate(0, -46%);
    }
    47% {
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
    59% {
      -webkit-transform: translate(0, -20%);
      transform: translate(0, -20%);
    }
    67% {
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
    75% {
      -webkit-transform: translate(0, -10%);
      transform: translate(0, -10%);
    }
    80% {
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
  }
  @-webkit-keyframes ldt-fall-in {
    0%, 35%, 59%, 75% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    18%, 47%, 67%, 80% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: translate(0, -100%);
      transform: translate(0, -100%);
    }
    18% {
      opacity: 1;
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
    35% {
      -webkit-transform: translate(0, -46%);
      transform: translate(0, -46%);
    }
    47% {
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
    59% {
      -webkit-transform: translate(0, -20%);
      transform: translate(0, -20%);
    }
    67% {
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
    75% {
      -webkit-transform: translate(0, -10%);
      transform: translate(0, -10%);
    }
    80% {
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
  }
  @keyframes ldt-fall-in-old {
    0% {
      -webkit-transform: translate(0, -139.8059%);
      transform: translate(0, -139.8059%);
      opacity: 0;
    }
    6% {
      -webkit-transform: translate(0, -126.113%);
      transform: translate(0, -126.113%);
    }
    9% {
      -webkit-transform: translate(0, -111.6773%);
      transform: translate(0, -111.6773%);
    }
    11% {
      -webkit-transform: translate(0, -97.5532%);
      transform: translate(0, -97.5532%);
    }
    13% {
      -webkit-transform: translate(0, -79.9952%);
      transform: translate(0, -79.9952%);
    }
    15% {
      -webkit-transform: translate(0, -59.6475%);
      transform: translate(0, -59.6475%);
    }
    17% {
      -webkit-transform: translate(0, -37.5424%);
      transform: translate(0, -37.5424%);
    }
    18% {
      -webkit-transform: translate(0, -26.2384%);
      transform: translate(0, -26.2384%);
    }
    19% {
      -webkit-transform: translate(0, -15.0112%);
      transform: translate(0, -15.0112%);
      opacity: 1;
    }
    24% {
      -webkit-transform: translate(0, -33.3136%);
      transform: translate(0, -33.3136%);
    }
    26% {
      -webkit-transform: translate(0, -45.8063%);
      transform: translate(0, -45.8063%);
    }
    36% {
      -webkit-transform: translate(0, -30.1431%);
      transform: translate(0, -30.1431%);
    }
    38% {
      -webkit-transform: translate(0, -16.6962%);
      transform: translate(0, -16.6962%);
    }
    40% {
      -webkit-transform: translate(0, -3.3162%);
      transform: translate(0, -3.3162%);
    }
    44% {
      -webkit-transform: translate(0, -17.1842%);
      transform: translate(0, -17.1842%);
    }
    56% {
      -webkit-transform: translate(0, -3.4519%);
      transform: translate(0, -3.4519%);
    }
    100% {
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-fall-in-old {
    0% {
      -webkit-transform: translate(0, -139.8059%);
      transform: translate(0, -139.8059%);
      opacity: 0;
    }
    6% {
      -webkit-transform: translate(0, -126.113%);
      transform: translate(0, -126.113%);
    }
    9% {
      -webkit-transform: translate(0, -111.6773%);
      transform: translate(0, -111.6773%);
    }
    11% {
      -webkit-transform: translate(0, -97.5532%);
      transform: translate(0, -97.5532%);
    }
    13% {
      -webkit-transform: translate(0, -79.9952%);
      transform: translate(0, -79.9952%);
    }
    15% {
      -webkit-transform: translate(0, -59.6475%);
      transform: translate(0, -59.6475%);
    }
    17% {
      -webkit-transform: translate(0, -37.5424%);
      transform: translate(0, -37.5424%);
    }
    18% {
      -webkit-transform: translate(0, -26.2384%);
      transform: translate(0, -26.2384%);
    }
    19% {
      -webkit-transform: translate(0, -15.0112%);
      transform: translate(0, -15.0112%);
      opacity: 1;
    }
    24% {
      -webkit-transform: translate(0, -33.3136%);
      transform: translate(0, -33.3136%);
    }
    26% {
      -webkit-transform: translate(0, -45.8063%);
      transform: translate(0, -45.8063%);
    }
    36% {
      -webkit-transform: translate(0, -30.1431%);
      transform: translate(0, -30.1431%);
    }
    38% {
      -webkit-transform: translate(0, -16.6962%);
      transform: translate(0, -16.6962%);
    }
    40% {
      -webkit-transform: translate(0, -3.3162%);
      transform: translate(0, -3.3162%);
    }
    44% {
      -webkit-transform: translate(0, -17.1842%);
      transform: translate(0, -17.1842%);
    }
    56% {
      -webkit-transform: translate(0, -3.4519%);
      transform: translate(0, -3.4519%);
    }
    100% {
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
      opacity: 1;
    }
  }
  .ldt-fall-in {
    -webkit-transform: translate(0, -100%);
    transform: translate(0, -100%);
    opacity: 0;
    -webkit-animation: ldt-fall-in 1s forwards;
    animation: ldt-fall-in 1s forwards;
  }
  @keyframes ldt-throw-in {
    0%, 50% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    30%, 70% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      -webkit-transform: translate(0, 100%);
      transform: translate(0, 100%);
    }
    30% {
      -webkit-transform: translate(0, -34%);
      transform: translate(0, -34%);
    }
    50% {
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
    70% {
      -webkit-transform: translate(0, -13%);
      transform: translate(0, -13%);
    }
    80% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes ldt-throw-in {
    0%, 50% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    30%, 70% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      -webkit-transform: translate(0, 100%);
      transform: translate(0, 100%);
    }
    30% {
      -webkit-transform: translate(0, -34%);
      transform: translate(0, -34%);
    }
    50% {
      -webkit-transform: translate(0, 0%);
      transform: translate(0, 0%);
    }
    70% {
      -webkit-transform: translate(0, -13%);
      transform: translate(0, -13%);
    }
    80% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  .ldt-throw-in {
    -webkit-animation: ldt-throw-in 1s linear forwards;
    animation: ldt-throw-in 1s linear forwards;
  }
  @keyframes ldt-vortex-in {
    0% {
      -webkit-transform: rotate(1800deg) scale(3);
      transform: rotate(1800deg) scale(3);
      opacity: 0;
    }
    90% {
      -webkit-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-vortex-in {
    0% {
      -webkit-transform: rotate(1800deg) scale(3);
      transform: rotate(1800deg) scale(3);
      opacity: 0;
    }
    90% {
      -webkit-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity: 1;
    }
  }
  .ld.ldt-vortex-in {
    -webkit-animation: ldt-vortex-in 1s infinite;
    animation: ldt-vortex-in 1s infinite;
    animation-timing-function: cubic-bezier(0.3, 0, 1, 0.7);
  }
  @keyframes ldt-vortex-out {
    0% {
      -webkit-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(1800deg) scale(3);
      transform: rotate(1800deg) scale(3);
      opacity: 0;
    }
  }
  @-webkit-keyframes ldt-vortex-out {
    0% {
      -webkit-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(1800deg) scale(3);
      transform: rotate(1800deg) scale(3);
      opacity: 0;
    }
  }
  .ld.ldt-vortex-out {
    -webkit-animation: ldt-vortex-out 1s infinite;
    animation: ldt-vortex-out 1s infinite;
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  @keyframes ldt-float-px-up-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(0, 30px);
      transform: translate(0, 30px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes ldt-float-px-up-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(0, 30px);
      transform: translate(0, 30px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  .ldt-float-px-up-in {
    -webkit-transform: translate(0, 30px);
    transform: translate(0, 30px);
    opacity: 0;
    -webkit-animation: ldt-float-px-up-in 1s forwards;
    animation: ldt-float-px-up-in 1s forwards;
  }
  @keyframes ldt-float-px-down-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(0, -30px);
      transform: translate(0, -30px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes ldt-float-px-down-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(0, -30px);
      transform: translate(0, -30px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  .ldt-float-px-down-in {
    -webkit-transform: translate(0, -30px);
    transform: translate(0, -30px);
    opacity: 0;
    -webkit-animation: ldt-float-px-down-in 1s forwards;
    animation: ldt-float-px-down-in 1s forwards;
  }
  @keyframes ldt-float-px-left-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(30px, 0);
      transform: translate(30px, 0);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0px, 0);
      transform: translate(0px, 0);
    }
  }
  @-webkit-keyframes ldt-float-px-left-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(30px, 0);
      transform: translate(30px, 0);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0px, 0);
      transform: translate(0px, 0);
    }
  }
  .ldt-float-px-left-in {
    -webkit-transform: translate(30px, 0);
    transform: translate(30px, 0);
    opacity: 0;
    -webkit-animation: ldt-float-px-left-in 1s forwards;
    animation: ldt-float-px-left-in 1s forwards;
  }
  @keyframes ldt-float-px-right-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(-30px, 0);
      transform: translate(-30px, 0);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes ldt-float-px-right-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(-30px, 0);
      transform: translate(-30px, 0);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  .ldt-float-px-right-in {
    -webkit-transform: translate(-30px, 0);
    transform: translate(-30px, 0);
    opacity: 0;
    -webkit-animation: ldt-float-px-right-in 1s forwards;
    animation: ldt-float-px-right-in 1s forwards;
  }
  @keyframes ldt-slide-px-left-in {
    0% {
      -webkit-transform: translate(-200px, 0);
      transform: translate(-200px, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-slide-px-left-in {
    0% {
      -webkit-transform: translate(-200px, 0);
      transform: translate(-200px, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .ldt-slide-px-left-in {
    -webkit-transform: translate(-200px, 0);
    transform: translate(-200px, 0);
    opacity: 0;
    -webkit-animation: ldt-slide-px-left-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-px-left-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-px-right-in {
    0% {
      -webkit-transform: translate(200px, 0);
      transform: translate(200px, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-slide-px-right-in {
    0% {
      -webkit-transform: translate(200px, 0);
      transform: translate(200px, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .ldt-slide-px-right-in {
    -webkit-transform: translate(200px, 0);
    transform: translate(200px, 0);
    opacity: 0;
    -webkit-animation: ldt-slide-px-right-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-px-right-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-px-top-in {
    0% {
      -webkit-transform: translate(0, -200px);
      transform: translate(0, -200px);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-slide-px-top-in {
    0% {
      -webkit-transform: translate(0, -200px);
      transform: translate(0, -200px);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .ldt-slide-px-top-in {
    -webkit-transform: translate(0, -200px);
    transform: translate(0, -200px);
    opacity: 0;
    -webkit-animation: ldt-slide-px-top-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-px-top-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-px-bottom-in {
    0% {
      -webkit-transform: translate(0, 200px);
      transform: translate(0, 200px);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-slide-px-bottom-in {
    0% {
      -webkit-transform: translate(0, 200px);
      transform: translate(0, 200px);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .ldt-slide-px-bottom-in {
    -webkit-transform: translate(0, 200px);
    transform: translate(0, 200px);
    opacity: 0;
    -webkit-animation: ldt-slide-px-bottom-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-px-bottom-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-px-left-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(-200px, 0);
      transform: translate(-200px, 0);
      opacity: 0;
    }
  }
  @-webkit-keyframes ldt-slide-px-left-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(-200px, 0);
      transform: translate(-200px, 0);
      opacity: 0;
    }
  }
  .ldt-slide-px-left-out {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation: ldt-slide-px-left-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-px-left-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-px-right-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(200px, 0);
      transform: translate(200px, 0);
      opacity: 0;
    }
  }
  @-webkit-keyframes ldt-slide-px-right-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(200px, 0);
      transform: translate(200px, 0);
      opacity: 0;
    }
  }
  .ldt-slide-px-right-out {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation: ldt-slide-px-right-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-px-right-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-px-top-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(0, -200px);
      transform: translate(0, -200px);
      opacity: 0;
    }
  }
  @-webkit-keyframes ldt-slide-px-top-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(0, -200px);
      transform: translate(0, -200px);
      opacity: 0;
    }
  }
  .ldt-slide-px-top-out {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation: ldt-slide-px-top-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-px-top-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-slide-px-bottom-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(0, 200px);
      transform: translate(0, 200px);
      opacity: 0;
    }
  }
  @-webkit-keyframes ldt-slide-px-bottom-out {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(0, 200px);
      transform: translate(0, 200px);
      opacity: 0;
    }
  }
  .ldt-slide-px-bottom-out {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-animation: ldt-slide-px-bottom-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-slide-px-bottom-out 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  }
  @keyframes ldt-rush-px-left-in {
    0% {
      -webkit-transform: translate(-200px, 0) skewX(45deg);
      transform: translate(-200px, 0) skewX(45deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    38% {
      -webkit-transform: translate(40px, 0) skewX(-35deg);
      transform: translate(40px, 0) skewX(-35deg);
    }
    56% {
      -webkit-transform: translate(-20px, 0) skewX(12deg);
      transform: translate(-20px, 0) skewX(12deg);
    }
    75% {
      -webkit-transform: translate(10px, 0) skewX(-7deg);
      transform: translate(10px, 0) skewX(-7deg);
    }
    100% {
      -webkit-transform: translate(0px, 0) skewX(0deg);
      transform: translate(0px, 0) skewX(0deg);
    }
  }
  @-webkit-keyframes ldt-rush-px-left-in {
    0% {
      -webkit-transform: translate(-200px, 0) skewX(45deg);
      transform: translate(-200px, 0) skewX(45deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    38% {
      -webkit-transform: translate(40px, 0) skewX(-35deg);
      transform: translate(40px, 0) skewX(-35deg);
    }
    56% {
      -webkit-transform: translate(-20px, 0) skewX(12deg);
      transform: translate(-20px, 0) skewX(12deg);
    }
    75% {
      -webkit-transform: translate(10px, 0) skewX(-7deg);
      transform: translate(10px, 0) skewX(-7deg);
    }
    100% {
      -webkit-transform: translate(0px, 0) skewX(0deg);
      transform: translate(0px, 0) skewX(0deg);
    }
  }
  .ldt-rush-px-left-in {
    -webkit-transform: translate(-200px, 0) skewX(45deg);
    transform: translate(-200px, 0) skewX(45deg);
    -webkit-animation: ldt-rush-px-left-in 1s linear forwards;
    animation: ldt-rush-px-left-in 1s linear forwards;
  }
  @keyframes ldt-rush-px-right-in {
    0% {
      -webkit-transform: translate(200px, 0) skewX(-45deg);
      transform: translate(200px, 0) skewX(-45deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    38% {
      -webkit-transform: translate(-40px, 0) skewX(35deg);
      transform: translate(-40px, 0) skewX(35deg);
    }
    56% {
      -webkit-transform: translate(20px, 0) skewX(-12deg);
      transform: translate(20px, 0) skewX(-12deg);
    }
    75% {
      -webkit-transform: translate(-10px, 0) skewX(7deg);
      transform: translate(-10px, 0) skewX(7deg);
    }
    100% {
      -webkit-transform: translate(0px, 0) skewX(0deg);
      transform: translate(0px, 0) skewX(0deg);
    }
  }
  @-webkit-keyframes ldt-rush-px-right-in {
    0% {
      -webkit-transform: translate(200px, 0) skewX(-45deg);
      transform: translate(200px, 0) skewX(-45deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    38% {
      -webkit-transform: translate(-40px, 0) skewX(35deg);
      transform: translate(-40px, 0) skewX(35deg);
    }
    56% {
      -webkit-transform: translate(20px, 0) skewX(-12deg);
      transform: translate(20px, 0) skewX(-12deg);
    }
    75% {
      -webkit-transform: translate(-10px, 0) skewX(7deg);
      transform: translate(-10px, 0) skewX(7deg);
    }
    100% {
      -webkit-transform: translate(0px, 0) skewX(0deg);
      transform: translate(0px, 0) skewX(0deg);
    }
  }
  .ldt-rush-px-right-in {
    -webkit-transform: translate(200px, 0) skewX(-45deg);
    transform: translate(200px, 0) skewX(-45deg);
    -webkit-animation: ldt-rush-px-right-in 1s linear forwards;
    animation: ldt-rush-px-right-in 1s linear forwards;
  }
  @keyframes ldt-fall-px-in {
    0% {
      -webkit-transform: translate(0, -139.8059px);
      transform: translate(0, -139.8059px);
      opacity: 0;
    }
    6% {
      -webkit-transform: translate(0, -126.113px);
      transform: translate(0, -126.113px);
    }
    9% {
      -webkit-transform: translate(0, -111.6773px);
      transform: translate(0, -111.6773px);
    }
    11% {
      -webkit-transform: translate(0, -97.5532px);
      transform: translate(0, -97.5532px);
    }
    13% {
      -webkit-transform: translate(0, -79.9952px);
      transform: translate(0, -79.9952px);
    }
    15% {
      -webkit-transform: translate(0, -59.6475px);
      transform: translate(0, -59.6475px);
    }
    17% {
      -webkit-transform: translate(0, -37.5424px);
      transform: translate(0, -37.5424px);
    }
    18% {
      -webkit-transform: translate(0, -26.2384px);
      transform: translate(0, -26.2384px);
    }
    19% {
      -webkit-transform: translate(0, -15.0112px);
      transform: translate(0, -15.0112px);
      opacity: 1;
    }
    24% {
      -webkit-transform: translate(0, -33.3136px);
      transform: translate(0, -33.3136px);
    }
    26% {
      -webkit-transform: translate(0, -45.8063px);
      transform: translate(0, -45.8063px);
    }
    36% {
      -webkit-transform: translate(0, -30.1431px);
      transform: translate(0, -30.1431px);
    }
    38% {
      -webkit-transform: translate(0, -16.6962px);
      transform: translate(0, -16.6962px);
    }
    40% {
      -webkit-transform: translate(0, -3.3162px);
      transform: translate(0, -3.3162px);
    }
    44% {
      -webkit-transform: translate(0, -17.1842px);
      transform: translate(0, -17.1842px);
    }
    56% {
      -webkit-transform: translate(0, -3.4519px);
      transform: translate(0, -3.4519px);
    }
    100% {
      -webkit-transform: translate(0, 0px);
      transform: translate(0, 0px);
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-fall-px-in {
    0% {
      -webkit-transform: translate(0, -139.8059px);
      transform: translate(0, -139.8059px);
      opacity: 0;
    }
    6% {
      -webkit-transform: translate(0, -126.113px);
      transform: translate(0, -126.113px);
    }
    9% {
      -webkit-transform: translate(0, -111.6773px);
      transform: translate(0, -111.6773px);
    }
    11% {
      -webkit-transform: translate(0, -97.5532px);
      transform: translate(0, -97.5532px);
    }
    13% {
      -webkit-transform: translate(0, -79.9952px);
      transform: translate(0, -79.9952px);
    }
    15% {
      -webkit-transform: translate(0, -59.6475px);
      transform: translate(0, -59.6475px);
    }
    17% {
      -webkit-transform: translate(0, -37.5424px);
      transform: translate(0, -37.5424px);
    }
    18% {
      -webkit-transform: translate(0, -26.2384px);
      transform: translate(0, -26.2384px);
    }
    19% {
      -webkit-transform: translate(0, -15.0112px);
      transform: translate(0, -15.0112px);
      opacity: 1;
    }
    24% {
      -webkit-transform: translate(0, -33.3136px);
      transform: translate(0, -33.3136px);
    }
    26% {
      -webkit-transform: translate(0, -45.8063px);
      transform: translate(0, -45.8063px);
    }
    36% {
      -webkit-transform: translate(0, -30.1431px);
      transform: translate(0, -30.1431px);
    }
    38% {
      -webkit-transform: translate(0, -16.6962px);
      transform: translate(0, -16.6962px);
    }
    40% {
      -webkit-transform: translate(0, -3.3162px);
      transform: translate(0, -3.3162px);
    }
    44% {
      -webkit-transform: translate(0, -17.1842px);
      transform: translate(0, -17.1842px);
    }
    56% {
      -webkit-transform: translate(0, -3.4519px);
      transform: translate(0, -3.4519px);
    }
    100% {
      -webkit-transform: translate(0, 0px);
      transform: translate(0, 0px);
      opacity: 1;
    }
  }
  .ldt-fall-px-in {
    -webkit-transform: translate(0, -139.8059px);
    transform: translate(0, -139.8059px);
    opacity: 0;
    -webkit-animation: ldt-fall-px-in 1s linear forwards;
    animation: ldt-fall-px-in 1s linear forwards;
  }
  @keyframes ldt-throw-px-in {
    0%, 50% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    30%, 70% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      -webkit-transform: translate(0, 100px);
      transform: translate(0, 100px);
    }
    30% {
      -webkit-transform: translate(0, -34px);
      transform: translate(0, -34px);
    }
    50% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    70% {
      -webkit-transform: translate(0, -13px);
      transform: translate(0, -13px);
    }
    80% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes ldt-throw-px-in {
    0%, 50% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    30%, 70% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      -webkit-transform: translate(0, 100px);
      transform: translate(0, 100px);
    }
    30% {
      -webkit-transform: translate(0, -34px);
      transform: translate(0, -34px);
    }
    50% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    70% {
      -webkit-transform: translate(0, -13px);
      transform: translate(0, -13px);
    }
    80% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  .ldt-throw-px-in {
    -webkit-animation: ldt-throw-px-in 1s linear forwards;
    animation: ldt-throw-px-in 1s linear forwards;
  }
  @keyframes ldt-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes ldt-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .ldt-fade-in {
    opacity: 0;
    -webkit-animation: ldt-fade-in 1s forwards;
    animation: ldt-fade-in 1s forwards;
    animation-timing-function: cubic-bezier(0, 0.3, 0.7, 1);
  }
  .ldt-fade-out {
    opacity: 1;
    -webkit-animation: ldt-fade-in 1s forwards;
    animation: ldt-fade-in 1s forwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
    animation-timing-function: cubic-bezier(0.3, 0, 1, 0.7);
  }
  @keyframes ldt-grow-h {
    0% {
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  @-webkit-keyframes ldt-grow-h {
    0% {
      -webkit-transform: scale(0, 1);
      transform: scale(0, 1);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  @keyframes ldt-grow-v {
    0% {
      -webkit-transform: scale(1, 0);
      transform: scale(1, 0);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  @-webkit-keyframes ldt-grow-v {
    0% {
      -webkit-transform: scale(1, 0);
      transform: scale(1, 0);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  .ldt-grow-ltr {
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-animation: ldt-grow-h 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-grow-h 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    -webkit-transform-origin: 0 !important;
    transform-origin: 0 !important;
  }
  .ldt-grow-rtl {
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-animation: ldt-grow-h 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-grow-h 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    -webkit-transform-origin: 100% !important;
    transform-origin: 100% !important;
  }
  .ldt-grow-ttb {
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-animation: ldt-grow-v 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-grow-v 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    -webkit-transform-origin: 50% 0 !important;
    transform-origin: 50% 0 !important;
  }
  .ldt-grow-btt {
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-animation: ldt-grow-v 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    animation: ldt-grow-v 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
    -webkit-transform-origin: 50% 100% !important;
    transform-origin: 50% 100% !important;
  }
  .infinite {
    animation-iteration-count: infinite;
  }